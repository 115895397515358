import { connect } from 'react-redux'
import ContentsLibraryComponent from '../components/ContentsLibraryComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  // currencyExchangeRate: display.currencyExchangeRate,
  // editCampaign: display.editCampaign,
  currency: userAccount.currency,
  libraryContents: display.libraryContents,
  loadingContentsbyCompany: display.loadingContentsbyCompany,
  token: userAccount.token,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  defaultContent: display.defaultContent,
  customContents: display.customContents,
  // editCampaignActive: display.editCampaignActive,
  brand: display.brand,
  // listResolutionWithContent: display.listResolutionWithContent,
})

const mapStateToDispatch = (dispatch) => ({
  remplaceContentFromCampaign: (contentId, contentToRemplace) => dispatch(DisplayActions.remplaceContentFromCampaign(contentId, contentToRemplace)),
  remplaceContentFromRelation: (contentId, displayId, contentToReplace) => dispatch(DisplayActions.remplaceContentFromRelation(contentId, displayId, contentToReplace)),
  getContentsByCompany : (companyId, params) => dispatch(DisplayActions.getContentsByCompanyRequest(companyId, params)),
  setLibraryContents : (contents) => dispatch(DisplayActions.setLibraryContents(contents)),
  setListOfContentsInCampaign: (contents) => dispatch(DisplayActions.setListOfContentsInCampaign(contents)),
  cleanListOfContentsInCampaign: () => dispatch(DisplayActions.cleanListOfContentsInCampaign()),
  updateDefaultContentRequest: id => dispatch(DisplayActions.updateDefaultContentRequest(id)),
  updateCustomContents: customContents => dispatch(DisplayActions.updateCustomContents(customContents)),
  setListResolutionWithContent: listResolutionWithContent => dispatch(DisplayActions.setListResolutionWithContent(listResolutionWithContent)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ContentsLibraryComponent)