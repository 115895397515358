import React, { useState} from 'react';
import { Icon, Row, Col, Popover, Button, Modal, Tooltip} from "antd";
import { getTotalPice } from "../services/display";
import NumberFormat from "./NumberFormat";
import moment from 'moment';
import CardListSummaryHolder from "./Styles/cardListSummaryStyle";
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/en-gb';

const CardListSummaryComponent = (props) => {
  const {t, i18n}  = useTranslation();
  const { campaign } = props;
  const [visibleSpotModal, setVisibleSpotModal] = useState(false);
  const [modalDisplayName, setModalDisplayName] = useState('');
  const [modalContentFile, setModalContentFile] = useState('');
  const [modalContentType, setModalContentType] = useState('image');

  const showSpotModal = (displayName, contentFile, contentType) => {
    setModalContentType('image');
    handleDisplayModal(true);
    setModalDisplayName(displayName);
    setModalContentFile(contentFile);
    setModalContentType(contentType);
  };

  const handleDisplayModal = function(){
    setVisibleSpotModal(!visibleSpotModal);
  }

  return (
    <CardListSummaryHolder>
      <div className="cardListSummary">
        <Row justify="center">
          <Col className={"text-title"}>{t("Summary")}<br /></Col>
          <Col>
            <div className='titleInfoDate'>{t("Campaign name")}</div>
            <div className='infoDate'>{campaign.name}</div>
          </Col>
          <Col className='listContent'>
            {campaign.contents_displays.map((display) => {
                return (
                    <div key={display.id}>
                      <div className='titleInfoDate'>{display.display_name}</div>
                       <div className='infoDateTwo'>
                        <span onClick={() => showSpotModal(display.display_name, display.content_file, display.content_type)}>
                          <Icon type="eye-o" />
                        </span>
                          ${display.paid_amount_with_profit}
                       </div>
                    </div>
                )
            })}
          </Col>
        </Row>
      </div>

      <Modal
        title={modalDisplayName}
        visible={visibleSpotModal}
        onOk={handleDisplayModal}
        onCancel={handleDisplayModal}
        footer={[
          <Button key="back" onClick={handleDisplayModal}>
            {t('Close')}
          </Button>,
        ]}
      >
        {modalContentType === 'video' ? (
          <video controls style={{width: "100%"}}>
            <source src={ modalContentFile } type="video/mp4" />
          </video>
        ) : (<img src={modalContentFile} style={{width: "100%"}} alt="" />)
        }
      </Modal>
    </CardListSummaryHolder>
  );
};

export default CardListSummaryComponent;